@use "sass:list";
@import "../../index.scss";

.welcome-section {
  position: relative;
  min-height: 100vh;
  width: 100%;
  @include center;
  text-align: center;
  .description {
    position: relative;
    @include center;
    width: 80%;
    // height: 80vh;

    .textBox {
      margin-top: 50px;
      width: 50%;
      color: white;
      p {
        text-align: center;
        font-size: 2.1em;
        @media (max-width: $small_mobile_width) {
          font-size: 1.6em;
        }
      }
      h1 {
        font-size: 3.5em;
        text-align: center;
        letter-spacing: 3px;
        margin-top: -20px;
        @media (max-width: $small_mobile_width) {
          font-size: 2.8em;
          letter-spacing: 3px;
          margin-top: -20px;
        }
      }
    }
  }
}

.welcome-section::before {
  content: "";
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)),
    url("background.png");
  background-attachment: fixed;
  position: absolute;
  background-size: cover;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-position: 50% 50%;
  @media (max-width: $small_mobile_width) {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-position: 30% 80%;
  }
}
