$small_mobile_width: 390px;
$mobile_width: 480px;
$tablet_width: 768px;
$medium_desktop_width: 990px;
$desktop_width: 1100px;
$large_desktop_width: 1280px;

@mixin center {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


