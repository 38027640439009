@import "../../index.scss";
.projects {
  height: auto;
  width: 100%;
  background-image: 
    linear-gradient(
      rgba(34, 42, 46, 0.9),
      rgba(34, 42, 46, 0.6)
    ),url("background.png");
    background-attachment: fixed;
  // background-image: url(project_background.jpg);
  background-size: cover;
  // background: #2a3239;
  // background-repeat:round;
  overflow: hidden;

  @include center;
  position: relative;
  text-align: center;
  // display:flex;
  .header {
    // @include center;
    @include center;
    color: white;
    position: top;
    font-size: 2rem;
    letter-spacing: 5px;
    margin-bottom: 20px;
  }
  .grid {
    height: auto;
    width:80%;
    display: flex;
    flex-wrap: wrap;
    @include center;
  }
}
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding:10px;
  // border: 2px solid #ccc;
  border-radius: 5px;
  // color:rgba(0, 0, 0, 0.1);
  background-color: rgb(29, 29, 29);
  width: 400px;
  height: 380px;
  max-height: 500px;
  margin: 10px;
  margin-bottom: 50px;
  cursor:pointer;
  .card-header{
    text-align: start;
    // background-color: aqua;
    width: 90%;
  }
  p {
    font-family:'Trebuchet MS';
    text-align:start;
  }
  .content {
    width: 90%;
    margin-top: -10%;
    // height: 50%;
  }
}

