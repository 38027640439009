@use "sass:list";
@import "../../index.scss";
.about-me {
  position: relative;
  @include center;
  height: 100%;
  width: 100%;
  background: radial-gradient(circle at center, #ffffff 50%, #d8f5ff 90%);
  .personal-facts {
    @include center;
    // width: 1200px;
    width: 85%;

    padding: 10px;
    .header {
      text-align: center;
      width: 75%;
      font-size: 2em;
      line-height: 50%;
    }
    .bio {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      text-align: center;
      width: 75%;
    }
    .personal-photo {
      height: 35vw;
      width: 100%;
      @media (max-width: $tablet_width) {
        height: 50vw;
        width: 100%;
      }
      @include center;
    }
    .hstack {
      // background-color: azure;
      width: 100%;
      flex-direction: row;
      align-items: flex-start;
      padding: 10px;
      @include center;
      .col {
        width: 45%;
        min-width: 300px;
        padding: 10px;
      }
    }
  }
}
.personal-facts-item {
  .personal-facts-head {
    display: flex;
    align-items: center;
  }
  .title {
    font-weight: 600;
    font-size: 2em;
    margin-bottom: -5px;
    background-clip: text;
    -webkit-background-clip: text;
  }
  .body {
    font-weight: 400;
    font-size: 1.05rem;
    line-height: 200%;
  }
}
@media (max-width: $mobile_width) {
  .personal-facts-item {
    .title {
      font-weight: 600;
      font-size: 1.5em;
      margin-bottom: -5px;
      background-clip: text;
      -webkit-background-clip: text;
    }
    .body {
      font-weight: 400;
      font-size: 1.0rem;
      line-height: 200%;
    }
    
  }
}
.swiper_container {
  height: 30rem;
  padding: 2rem 0;
  position: relative;
  // background-color: aquamarine;
}

.swiper-slide {
  width: 30rem;
  height: 25rem;
  position: relative;
}

@media (max-width: 500px) {
  .swiper_container {
    height: 14rem;
    // background-color: aquamarine;
  }
  .swiper-slide {
    width: 13rem !important;
    height: 11rem !important;

  }
  .swiper-slide img {
    width: 13rem !important;
    height: 11rem !important;
  }
}

.swiper-slide img {
  width: 30rem;
  height: 25rem;
  border-radius: 2rem;
  object-fit: cover;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.slider-controler {
  position: relative;
  bottom: 2rem;
  // height:auto;
  display: flex;
  align-items: center;
  justify-content: center;
  // background: cadetblue;
}

.slider-controler .swiper-button-next {
  left: 59% !important;
  transform: translateX(-59%) !important;
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-next {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-prev {
    left: 30% !important;
    transform: translateX(-30%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
  }
}

.slider-controler .slider-arrow {
  background: white;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  left: 41%;
  bottom: 1rem;
  transform: translateX(-41%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.25));
}

.slider-controler .slider-arrow ion-icon {
  font-size: 1.5rem;
  color: #222224;
}

.slider-controler .slider-arrow::after {
  content: '';
}

.swiper-pagination {
  position: relative;
  width: 4rem;
  bottom: 1rem;
}

.swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: black;
}
